<template>
  <div>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="this.$router.currentRoute.path != '/home'"
    >
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>数据源管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      :inline="true"
      :model="dataForm"
    >
      <el-form-item>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-input v-model="dataForm.dataSourceName" placeholder="数据源名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="queryByName()">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-tag>筛选:</el-tag>
        <el-select v-model="sourceType" placeholder="数据源类别" size="mini" style="width: 130px;"
                   @change="changeType()">
          <el-option v-for="data in sourceOptions" :key="data.id" :label="data.label" :value="data.label">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-table v-show="(sourceType ==='数据库') " :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
              style="width: 100%;">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>

      <el-table-column label="序号" header-align="center" align="center" width="80" type="index">
        <template slot-scope="scope">
          <span>{{(pageIndex - 1) * pageSize + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alias" header-align="center" align="center" label="数据源名称" width="100">
      </el-table-column>
      <el-table-column prop="dbname" header-align="center" align="center" label="数据库名称" width="100">
      </el-table-column>
      <el-table-column prop="db" header-align="center" align="center" label="数据库类型" width="100">
      </el-table-column>
      <el-table-column prop="ip" header-align="center" align="center" label="ip" width="120">
      </el-table-column>
      <el-table-column prop="port" header-align="center" align="center" label="端口" width="80">
      </el-table-column>
      <el-table-column prop="username" header-align="center" align="center" label="数据库用户名" width="80">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" label="负责人邮箱" width="150">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" width="150" label="负责人电话">
      </el-table-column>
      <el-table-column prop="url" header-align="center" align="center" width="280" label="url">
      </el-table-column>

      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="20%"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
          >修改</el-button
          >
          <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-table v-show="(sourceType ==='文件') " :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
              style="width: 100%;">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>
      <el-table-column label="序号" header-align="center" align="center" width="80" type="index">
        <template slot-scope="scope">
          <span>{{(pageIndex - 1) * pageSize + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alias" header-align="center" align="center" label="数据源名称" width="100">
      </el-table-column>
      <el-table-column prop="name" header-align="center" align="center" label="文件名称" width="120">
      </el-table-column>
      <el-table-column prop="type" header-align="center" align="center" label="文件类型" width="100">
      </el-table-column>
      <el-table-column prop="localPath" header-align="center" align="center" width="180" label="本地路径">
      </el-table-column>
      <el-table-column prop="minioPath" header-align="center" align="center" width="180" label="minio路径">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" label="负责人邮箱" width="150">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" width="150" label="负责人电话">
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="20%"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
          >修改</el-button
          >
          <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <!--      <el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button v-if="isAuth('dataset:list')" type="text" size="small" @click="userwatch(scope.row)">查看-->
      <!--          </el-button>-->
      <!--          <el-button v-if="isAuth('dataset:update')" type="text" size="small"-->
      <!--                     @click="addOrUpdateHandle(scope.row)">修改</el-button>-->
      <!--          <el-button v-if="isAuth('dataset:delete')" type="text" size="small"-->
      <!--                     @click="deleteHandle(scope.row)">-->
      <!--            删除</el-button>-->
      <!--          <el-button type="text" size="small" @click="downloadDataset(scope.row.datasetName)">下载-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <el-table v-show="(sourceType ==='传感器') " :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
              style="width: 100%;">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>
      <el-table-column label="序号" header-align="center" align="center" width="80" type="index">
        <template slot-scope="scope">
          <span>{{(pageIndex - 1) * pageSize + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alias" header-align="center" align="center" label="数据源名称" width="100">
      </el-table-column>
      <el-table-column prop="name" header-align="center" align="center" label="传感器名称" width="120">
      </el-table-column>
      <el-table-column prop="info" header-align="center" align="center" label="数据列表" width="350">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" label="负责人邮箱" width="200">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" width="200" label="负责人电话">
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="20%"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
          >修改</el-button
          >
          <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <!--      <el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button v-if="isAuth('dataset:list')" type="text" size="small" @click="userwatch(scope.row)">查看-->
      <!--          </el-button>-->
      <!--          <el-button v-if="isAuth('dataset:update')" type="text" size="small"-->
      <!--                     @click="addOrUpdateHandle(scope.row)">修改</el-button>-->
      <!--          <el-button v-if="isAuth('dataset:delete')" type="text" size="small"-->
      <!--                     @click="deleteHandle(scope.row)">-->
      <!--            删除</el-button>-->
      <!--          <el-button type="text" size="small" @click="downloadDataset(scope.row.datasetName)">下载-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <el-table v-show="(sourceType ==='第三方') " :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
              style="width: 100%;">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>
      <el-table-column label="序号" header-align="center" align="center" width="80" type="index">
        <template slot-scope="scope">
          <span>{{(pageIndex - 1) * pageSize + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alias" header-align="center" align="center" label="数据源名称" width="100">
      </el-table-column>
      <el-table-column prop="name" header-align="center" align="center" label="第三方名称" width="120">
      </el-table-column>
      <el-table-column prop="dataList" header-align="center" align="center" label="数据列表" width="350">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" label="负责人邮箱" width="150">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" width="150" label="负责人电话">
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="20%"
          label="操作"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
          >修改</el-button
          >
          <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <!--      <el-table-column fixed="right" header-align="center" align="center" width="200" label="操作">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-button v-if="isAuth('dataset:list')" type="text" size="small" @click="userwatch(scope.row)">查看-->
      <!--          </el-button>-->
      <!--          <el-button v-if="isAuth('dataset:update')" type="text" size="small"-->
      <!--                     @click="addOrUpdateHandle(scope.row)">修改</el-button>-->
      <!--          <el-button v-if="isAuth('dataset:delete')" type="text" size="small"-->
      <!--                     @click="deleteHandle(scope.row)">-->
      <!--            删除</el-button>-->
      <!--          <el-button type="text" size="small" @click="downloadDataset(scope.row.datasetName)">下载-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
<!--新增 start-->
    <el-table v-show="(sourceType ==='opc') " :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
              style="width: 100%;">
      <el-table-column type="selection" header-align="center" align="center" width="50">
      </el-table-column>

      <el-table-column label="序号" header-align="center" align="center" width="80" type="index">
        <template slot-scope="scope">
          <span>{{(pageIndex - 1) * pageSize + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alias" header-align="center" align="center" label="数据源名称" width="100">
      </el-table-column>
      <el-table-column prop="ip" header-align="center" align="center" label="ip" width="120">
      </el-table-column>
      <el-table-column prop="port" header-align="center" align="center" label="端口" width="80">
      </el-table-column>
      <el-table-column prop="username" header-align="center" align="center" label="用户名" width="80">
      </el-table-column>
      <el-table-column prop="email" header-align="center" align="center" label="负责人邮箱" width="150">
      </el-table-column>
      <el-table-column prop="mobile" header-align="center" align="center" width="150" label="负责人电话">
      </el-table-column>

      <el-table-column
              fixed="right"
              header-align="center"
              align="center"
              min-width="20%"
              label="操作"
      >
        <template slot-scope="scope">
          <el-button
                  type="text"
                  size="small"
                  @click="addOrUpdateHandle(scope.row.id)"
          >修改</el-button
          >
          <el-button
                  type="text"
                  size="small"
                  @click="deleteHandle(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

<!--新增 end-->


    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      :sourceType="sourceType"
      @initData="initData"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from "./dataSource-add-or-update";
export default {
  data() {
    return {
      name:"数据源管理",
      dataForm: {
        dataSourceName: ''
      },
      sourceType: "数据库",
      sourceOptions: [{
        id: 1,
        label: "数据库"
      }, {
        id: 2,
        label: "文件"
      }, {
        id: 3,
        label: "传感器",
      }, {
        id: 4,
        label: "第三方"
      },{
        id: 5,
        label: "opc"
      }],
      setting: {
        batchremove:"",
        advancesearch:"",
        numberperpage:""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate
  },
  mounted() {
    this.initData()
    // this.getDataList();
    //根据名字查询系统设置中的结果
    this.getRequest(
      "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(resp.data);
        this.setting.batchRemove = resp.data.batchremove;
        this.setting.advancesearch = resp.data.advancesearch;
        this.pageSize = resp.data.numberperpage;
      }
    });
  },
  methods: {
    // 获取数据源列表
    initData() {
      this.dataListLoading = true
      if (this.sourceType === "数据库") {
        this.getRequest("/dataSource/list/db?page=" + this.pageIndex + "&size=" + this.pageSize).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "文件") {
        this.getRequest("/dataSource/list/file?page=" + this.pageIndex + "&size=" + this.pageSize).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "传感器") {
        this.getRequest("/dataSource/list/sensor?page=" + this.pageIndex + "&size=" + this.pageSize).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "第三方") {
        this.getRequest("/dataSource/list/td?page=" + this.pageIndex + "&size=" + this.pageSize).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "opc") {
        this.getRequest("/dataSource/list/opc?page=" + this.pageIndex + "&size=" + this.pageSize).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
    },
    queryByName() {
      this.dataListLoading = true
      if (this.sourceType === "数据库") {
        this.getRequest("/dataSource/listByName/db?page=" + this.pageIndex + "&size=" + this.pageSize + "&name=" + this.dataForm.dataSourceName).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "文件") {
        this.getRequest("/dataSource/listByName/file?page=" + this.pageIndex + "&size=" + this.pageSize + "&name=" + this.dataForm.dataSourceName).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "传感器") {
        this.getRequest("/dataSource/listByName/sensor?page=" + this.pageIndex + "&size=" + this.pageSize + "&name=" + this.dataForm.dataSourceName).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "第三方") {
        this.getRequest("/dataSource/listByName/td?page=" + this.pageIndex + "&size=" + this.pageSize + "&name=" + this.dataForm.dataSourceName).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
      if (this.sourceType === "opc") {
        this.getRequest("/dataSource/listByName/opc?page=" + this.pageIndex + "&size=" + this.pageSize + "&name=" + this.dataForm.dataSourceName).then((resp) => {
          if (resp) {
            console.log(resp)
            this.dataList = resp.data.data;
            this.totalPage = resp.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
          this.dataListLoading = false
        })
      }
    },
    changeType() {
      this.dataForm.dataSourceName = undefined
      this.pageIndex = 1
      this.pageSize = 10
      this.initData()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.initData();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.initData();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.roleId;
          });
      this.$confirm(
        `确定对[id=${ids.join(",")}]进行[${id ? "删除" : "批量删除"}]操作?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (this.sourceType == "数据库") {
            this.$http({
              url: this.$http.adornUrl("/dataSource/delete/db"),
              method: "post",
              data: this.$http.adornData(ids, false),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
                this.initData()
              } else {
                this.$message.error(data.msg);
              }
            });
          }
          if (this.sourceType == "文件") {
            this.$http({
              url: this.$http.adornUrl("/dataSource/delete/file"),
              method: "post",
              data: this.$http.adornData(ids, false),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
                this.initData()
              } else {
                this.$message.error(data.msg);
              }
            });
          }
          if (this.sourceType == "传感器") {
            this.$http({
              url: this.$http.adornUrl("/dataSource/delete/sensor"),
              method: "post",
              data: this.$http.adornData(ids, false),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
                this.initData()
              } else {
                this.$message.error(data.msg);
              }
            });
          }
          if (this.sourceType == "第三方") {
            this.$http({
              url: this.$http.adornUrl("/dataSource/delete/td"),
              method: "post",
              data: this.$http.adornData(ids, false),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
                this.initData()
              } else {
                this.$message.error(data.msg);
              }
            });
          }
          if (this.sourceType == "opc") {
            this.$http({
              url: this.$http.adornUrl("/dataSource/delete/opc"),
              method: "post",
              data: this.$http.adornData(ids, false),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
                this.initData()
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
